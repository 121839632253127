/*==============================
	forms
==============================*/
.pass-text {
    color: #000;
}
.jform {
    color: $text-reverse;
    
    .jform-title {
        font-size: 24px;

    }
    .jform-title-overform {        
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: translateY(-50%);

        .jform-title {
            background-color: $text-reverse;
            border: 2px solid $text-color;
            padding: 10px 15px;
            font-size: 20px;
            font-weight: $text-bold;
            line-height: 1;
            color: $text-color;
            display: inline-block;

            span {
                display: block;
            }
        }
    }
	.jform-group {
		margin-bottom: 8px;
		overflow: hidden;
	}

	.jform-input {
		width: 100%;
		appearance: none;
		background-image: none;
        background-color: $black-background;
        border: 0px none transparent;
        //border: 1px solid $text-color;
        // border-bottom: 1px solid $text-color;
        // border-radius: 0px;
		padding: 5px;
		color: $text-color;
		box-sizing: border-box;
        appearance: none;
        box-shadow: none;
        //text-align: center;
    }
	.jform-select {
		width: 100%;
		appearance: none;
		background-image: none;
        background-color: $black-background;
        border: 0px solid transparent;
        //border: 1px solid $text-color;
        //border-radius: 0px;
		padding: 5px;
		color: $text-color;
		box-sizing: border-box;
        font-size: 16px;
    }

    .jform-label {
        color: $text-reverse;
        font-weight: 300;
        margin: 0;
        padding: 0;
    }

	.errors {

		font-size: 16px;
		text-align: center;
		padding: 15px;
		margin-top: 25px;
		border: 2px dashed $text-error;
		//background-color: $light-background;
		color: $text-reverse;

		span {  color: $text-reverse; font-weight: bold; display: block; margin-top: 15px; }

		& span:first-child { margin: 0; }

		span:before {
			content: "!";
			color: $text-color;
		    background-color: $text-error;
		    padding: 0 7px;
		    border-radius: 50%;
		    margin-right: 5px;
		    font-weight: bold;
		}
	}

    .jform-group--recaptcha {
        position: relative;
        padding-bottom: 80px;

        .g-recaptcha {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

    }

    @media (min-width: 768px) {
        .jform-title {
            font-size: 30px;
        }
    }
    @media (min-width: 992px) {

    }
    @media (min-width: 1200px) {

    }
}

.jform-bottom-message {
	font-size: 16px;
}

.jform-plain-link {
    color: $text-reverse;
} 
.jform-group--checkbox input:not(:checked),
.jform-group--checkbox input:checked {
  position: absolute;
  left: -9999px;
}
.jform-group--checkbox input:not(:checked) + label,
.jform-group--checkbox input:checked + label {
  font-size: 14px;
  color: rgba(255,255,255,0.6);
  font-weight: normal;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin: 0;
}
.jform-group--checkbox input:not(:checked) + label a,
.jform-group--checkbox input:checked + label a {
  color: $text-highlight;
}
.jform-group--checkbox input:not(:checked) + label a:hover,
.jform-group--checkbox input:checked + label a:hover {
  color: $text-highlight;
}
.jform-group--checkbox input:not(:checked) + label:before,
.jform-group--checkbox input:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background-color: rgba(210,201,255,0.04);
  border-radius: 6px;
}
.jform-group--checkbox input:not(:checked) + label:after,
.jform-group--checkbox input:checked + label:after {
  font-family: 'Ionicons';
  content: '\f3ff';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  font-size: 22px;
  line-height: 20px;
  text-align: center;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
  color: $text-highlight;
}
.jform-group--checkbox input:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
}
.jform-group--checkbox input:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}
.jform-group--checkbox label::-moz-selection {
  background: transparent;
  color: rgba(255,255,255,0.6);
}
.jform-group--checkbox label::selection {
  background: transparent;
  color: rgba(255,255,255,0.6);
}

.check-box {
	display: block;
	position: relative;
	cursor: pointer;
	font-size: 22px;
	user-select: none;
}

.check-box input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	width: 0;
	height: 0;
}

.checkmark {
	position: absolute;
	width: 25px;
	height: 25px;
	background-color: $checkbox-bg;
	top: 0;
	left: 0;

	&:after {
		content: "";
		position: absolute;
	    left: 8px;
	    top: 2px;
	    width: 10px;
	    height: 15px;
    	border: solid $checkbox-color;
	    border-width: 0 2px 2px 0;
		transform: rotate(45deg);
		opacity: 0;
	}
}

.check-box:hover input ~ .checkmark:after {
	opacity: 0.2;
}

.check-box input:checked ~ .checkmark:after {
	opacity: 1;
}

.jform-group--checkbox {
	margin: 20px auto;
	display: flex;
	//align-items: center;

    a:hover {
        color: $text-color;
    }

	.check-box {
		width: 25px;
		height: 25px;
		margin: 0px;
		display: block;
	}
	.remember {
        padding-left: 15px;
        font-size: 14px;
        //line-height: 1em;
        user-select: none;
        margin: 0;
        //padding-bottom: 2px;
        color: $text-reverse;
        line-height: 1.2;
    }

}

// select 2
.select2-container .select2-selection--single {
    height: 34px;
}
.select2-container--default .select2-selection--single {
    background-color: $black-background;
    border: 0px none transparent;
    border-radius: 0px;
    padding: 2px 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: $text-color transparent transparent transparent;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b,
.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: $text-color;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    font-size: 16px;
    padding-left: 5px;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: $grigio-scuro;
}
.select2-results__options {
    font-size: 16px;
}

.select2-results__option.select2-results__option--selectable {
    border-bottom: 1px dashed;
}

.select2-dropdown {
    color: $text-reverse;
    background-color: $grigio-chiaro;
}


.jform-title-overform {        
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-50%);

    .jform-title {
        background-color: $text-reverse;
        border: 2px solid $text-color;
        padding: 10px 15px;
        font-size: 20px;
        font-weight: $text-bold;
        line-height: 1;
        color: $text-color;
        display: inline-block;

        span {
            display: block;
        }
    }
}

@media (min-width: 992px) {
    
    .jform {

        .jform-title-overform {        

            .jform-title {
    
                span {
                    display: block;
                }
            }
        }
    }      

}