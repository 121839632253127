html {
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}
html, body {
    height: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}
body {
	font-family: $main-font-family, sans-serif;
	font-weight: 400;
    -webkit-font-smoothing: antialiased;
    
    color: $text-color;
}

a,
a:hover,
a:active,
a:focus {
	outline: none;
}

a {
		
	&.link-highlight {
		
		color: $text-highlight;
        
        &:hover {
            color: darken($text-highlight, 15%);
        }
	}

}

input,
textarea,
select {
	padding: 0;
	margin: 0;
	border-radius: 0;
	appearance: none;
	box-shadow: none;
	text-shadow: none;	
}

input[type='password'] {
    font-family: sans-serif;
}

input:focus,
textarea:focus,
select:focus {
	outline: none;
}
textarea {
	resize: none;
}
select::-ms-expand {
	display: none;
}

button {
	border: none;
	background-color: transparent;
	cursor: pointer;
	outline: none;
	text-shadow: none;
	box-shadow: none;
	
	&:focus {
		outline: none;
	}

}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

img {
	width: 100%;
	height: auto;
}

/*
video {
	width: 100%    !important;
	height: auto   !important;
}
*/

.preloading {
	
	* {
		transition: none !important;
	}
	
}


::selection {
	background: $selection-background;
	color: $selection-color;
	text-shadow: none;
}

::placeholder {
	color: $placeholder-color;
	opacity: 1;
	overflow: visible;
	font-weight: $placeholder-weight;
	font-size: $placeholder-font-size;
	font-style: $placeholder-font-style;
}
