.btn-form-submit {

    color: $text-color;
    background-color: $text-reverse;
    text-transform: uppercase;
    border-radius: 0;
    font-size: 26px;
    margin: 0 auto;
    text-align: center;
    font-weight: $text-bold;
    // border: 1px solid $text-highlight;

    &:hover, &:active {
        font-weight: bold;
        color: $text-color;
        // background-color: $text-highlight;
        // border: 1px solid $text-highlight;

    }
    &:focus, &:active {
        transform: scale(0.95);
        // color: $text-reverse;
        // background-color: $text-highlight;
        // border: 1px solid $text-highlight;

    }
}
.btn-pmi-filled {
  color: $bianco;
  background: $azzurro;
  padding: 0 1.5rem;
  margin: 0 0.5rem;
}

.btn-pmi-outline {
  background: $bianco;
  color: $azzurro;
  border: 1px solid $azzurro;
  margin-bottom: 1.5rem;
  &:hover, &:active,&.active {
    background: $azzurro;
    color: $text-reverse;
    border: 1px solid $azzurro;
  }
}


@media screen and (min-width: 992px) {
  .btn-pmi-filled {
    padding: 0 4rem;
    margin: 0 3rem;
    font-size: 22px;
  }
}


// buttons for languages
ul.header__languages.d-flex{

  justify-content: end;
  margin-bottom: 15px;
  @media screen and (min-width:980px){
    justify-content: center;
    margin-bottom: 0;
  }


  li {
    margin: 0 0 0 10px;
    @media screen and (min-width:980px){
      margin: 0 10px;
    }



    div{
      color:white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 30px;
      height: 25px;
      background-size: 30px;
      background-repeat: no-repeat;
      background-position: center center;
      opacity: 0.5;
      &.lang-active{
        opacity: 1 !important;
      }
      &:hover{
        opacity: 1 !important;
      }


    }

  }

}
