body.landing {

    header.main-header-special .main-nav {
        max-width: 650px;
        margin: 0 auto;
        //width: 100%;
    }

    @media (min-width: 992px) {
        header.main-header-special .main-nav {            
            margin: 0;

            .nav-logo {
                display: block;
            }
            .nav-img-logo {
                height: 100px;                
            }
        }
    }

    // #content-box {
    //     margin: 3rem 0;
    // }

    // .footer-logo {
    //     max-width: 80%;
    // }

    // @media (min-width: 400px) {
    //     .logo, .logo-main {
    //         img {
    //             max-width: 80%;
    //         }
    //     }
    //     .footer-logo {
    //         max-width: 95%;
    //     }
    // }

    // @media (min-width: 992px) {
    //     & {
    //         background-position: center right;
    //     }
    // }

    // @media (min-width: 1200px) {
    //     .left-content {
    //         margin-left: 4rem;
    //     }
    // }
}
