.text-color { color: $text-color }
.text-highlight { color: $text-highlight }
.text-reverse { color: $text-reverse }
.text-error { color: $text-error }

.text-strong { font-weight: bold;}
.text-bold { font-weight: 700;}
.text-semibold { font-weight: 600;}
.text-regular { font-weight: 400;}
.text-light { font-weight: 300;}
.text-thin { font-weight: 200;}

.link-color { color: $link-color }

.text-underline { text-decoration: underline; } 

.alt-font { font-family: $alt-font-family;}

.bg-highlight { background-color: $text-highlight }
.bg-white { background-color: $white-background }

.line-divider {
    border-top: 3px solid $text-color;
    height: 3px;
}

.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
}


.fade {
	transition: opacity 0.25s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
	
	.fade {
		transition: none;
	}
}

.fade:not(.show) {
	opacity: 0;
}

@media (min-width: 992px) {
	.to-992 {display: none;}    
}
@media (max-width: 991px) {
	.from-992 {display: none;}    
}



