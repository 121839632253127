@import 'partials/fonts';
@import 'partials/variables';
@import 'partials/mixins';


$enable-gradients: true;
$link-color:                              $link-color !default;
$link-decoration:                         none !default;
$link-hover-color:                        $text-highlight !default;
$link-hover-decoration:                   none !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 35% !default;
$border-radius:0;


$theme-colors: (
  "default": #fff,
  "event": $text-highlight,
);

// Resolved from webpack
@import "bootstrap";

@import 'partials/common';
@import 'partials/helpers';
@import 'partials/layout';
@import 'partials/footer';
@import 'partials/components';
@import 'partials/countdown';
@import 'partials/navbar';
@import 'partials/live';
@import 'partials/forms';
@import 'partials/buttons';
//@import 'partials/agenda';
@import 'partials/survey';
//@import 'partials/thankyou';
@import 'partials/landing';
@import 'partials/access';
@import 'partials/password-meter';







// @import 'partials/ie';
// @import 'partials/scenes';

