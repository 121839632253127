@font-face {
    font-family: 'DIN Pro Cond';
    src: url('../fonts/DINPro-CondensedMedium.eot');
    src: url('../fonts/DINPro-CondensedMedium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DINPro-CondensedMedium.woff2') format('woff2'),
    url('../fonts/DINPro-CondensedMedium.woff') format('woff'),
    url('../fonts/DINPro-CondensedMedium.svg#DINPro-CondensedMedium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DIN Pro Cond';
    src: url('../fonts/DINPro-CondensedMediumItalic.eot');
    src: url('../fonts/DINPro-CondensedMediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DINPro-CondensedMediumItalic.woff2') format('woff2'),
    url('../fonts/DINPro-CondensedMediumItalic.woff') format('woff'),
    url('../fonts/DINPro-CondensedMediumItalic.svg#DINPro-CondensedMediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

