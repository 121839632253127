.survey {

    .select2-dropdown {
        background-color: transparent;
        border-color: $text-highlight;
        color: $text-color;
        text-align: center;
        font-weight: 600;
    }

    .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .select2-results__option:nth-child(odd) {
        background-color: rgba(255, 255, 255, 0.2);
    }

    .select2-container--default .select2-results>.select2-results__options {
        max-height: 252px;
    }
    .select2-container--open .select2-dropdown--below {
        
    }

    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
        
        border-color:$text-color transparent transparent transparent;
        border-width: 13px 10px 0 10px;
        left: -5px;
        top: 14px;

    }
}


#poll-box {
	    
    width: 100%;
    background-color: $dark-background;
    border: 1px solid $text-highlight;
    border-radius: 20px;
    padding: 25px;    

	.poll {
		
        text-align: center;
		
        .divider {
			text-align: center;
		}
		
		.rating.color-default .star-container .star i {
		  color: $text-highlight;
		}
		
		.star {
			cursor: pointer;
            width: 50px;
		}

        .rating {
            margin-bottom: 2rem;
        }

	}
    .poll-title, .poll-body, .poll-footer {}

    .poll-title {
        position: relative;
        display: inline-block;
        margin-bottom: 35px;
        font-weight: $text-bold;
        font-size: 30px;
        
    
        // &:after {
        //     content: '';
        //     width: 100px;
        //     height: 5px;
        //     background-color: $text-highlight;
        //     position: absolute;
        //     bottom: -20px;
        //     left: 0;
        // }
    }

    .poll-description {
        font-size: 20px;
          
    }

    .poll-body.poll-body-small {
        max-width: 350px;
        margin: 0 auto;

    }

    .poll-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        
        .poll-page {
            font-size: 28px;
            
        }
        
        .poll-btn {
            color: $text-color;

            &:hover {
                color: $text-highlight;
            }
            &:disabled {
                color: $survey-disabled-arrow;
            }

            i {
                font-size: 40px;
            }
        }

        .prev-btn, .next-btn {
            
            img {
                max-width: 25px;
            }
            svg {
                width: 25px;
                height: auto;
            }
        }
    }
    

    

    
    .poll-msg {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .poll-selector {
        margin-bottom: 290px;
        max-width: 420px;
        margin-left: auto;
        margin-right: auto;
    }

    .select2-container--default .select2-selection--single {
        background-color: transparent;
        border: 2px solid $text-highlight;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered,
    .select2-selection__placeholder {
        color: #fff;
        font-weight: 600;
    }

    .select2-container .select2-selection--single {
        height: 42px;
    }

    .poll-options {
        text-align: left;
        margin: 0 auto;
    }
    /* Customize the label (the container) */
    .poll-option-box {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default radio button */
      .poll-option-box input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      
      /* Create a custom radio button */
      .poll-option-label {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #8e8e8e;
        border-radius: 50%;
      }
      
      /* On mouse-over, add a grey background color */
      .poll-option-box:hover input ~ .poll-option-label {
        background-color: #ccc;
      }
      
      /* When the radio button is checked, add a blue background */
      .poll-option-box input:checked ~ .poll-option-label {
        background-color: #2196F3;
      }
      
      /* Create the indicator (the dot/circle - hidden when not checked) */
      .poll-option-label:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the indicator (dot/circle) when checked */
      .poll-option-box input:checked ~ .poll-option-label:after {
        display: block;
      }
      
      /* Style the indicator (dot/circle) */
      .poll-option-box .poll-option-label:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
      
      .poll-text-value {
          padding: 15px;
          background-color: $light-background;
          color: $text-color;
      }
}

@media (min-width: 632px) {
	
}

@media (min-width: 768px) {
	
    #poll-box {

        .poll-title {
            font-size: 34px;
        }

        .poll-description {
            font-size: 26px;
            //padding-top: 3rem;
            
        }
        .poll-intro .poll-description {
            //padding-top: 20vh;
            
        }

        .poll-body.poll-body-small {
            max-width: 450px;
        
    
        }

        
    
    }

}


@media (min-width: 992px) {

    .survey {

        .select2-dropdown {
            font-size: 20px;
        }
    
    }
	#poll-box {
	
        .poll-title {
            font-size: 46px;
        }

        .poll-description {
            font-size: 30px;
        }

        .poll-body.poll-body-small {
            max-width: 700px;
    
        }
        
        .poll-msg {
            font-size: 30px;
        }
        .poll-selector {
            max-width: 550px;
            font-size: 20px;
        }
        .poll-footer {
            
            .poll-page {
                font-size: 36px;                
            }
            
            .poll-btn {
                i {
                    font-size: 70px;
                }
            }
            
        }
    
	}
}

@media (min-width: 1200px) {

	#poll-box {
	
		max-width: 1280px;
        margin: 0 auto;
				
		.poll-title {
            font-size: 56px;
        }

        .poll-description {
            font-size: 40px;
        }
        
        .poll-msg {
            font-size: 40px;
        }
        .poll-options {
        
            width: 80%;
            
        }
    
		
	}
}